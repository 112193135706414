//fallback page for gatsby

import React from "react"

const NotFoundPage = () => (
  <div style={{
    minHeight: '100vh',
    marginTop: '100px',
    textAlign: 'center'
  }}>
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </div>
)

export default NotFoundPage
